.EmployerInviteRequestCard {
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: #242e47;
    font-family: Lato,sans-serif;
    font-size: 90%;
    text-shadow: 2px 2px 5px black;
    color: white;
    height: 100%;
}

@media screen and (min-width: 1000px) {
    .EmployerInviteRequestCard {
        margin: 0%;
        background-color: #242e47;
        font-family: Lato,sans-serif;
        font-size: 90%;
        text-shadow: 2px 2px 5px black;
        color: white;
        height: 100%;
    }
}
.createButton {
    margin-top: 10%;
    margin-left: -65%;
    width: 100%;
    background-color: #1e5b88;
    text-shadow: none;
    
}


@media screen and (min-width: 1000px) {
    .createButton {
        margin-top: 10%;
        margin-left: 0;
        width: 100%;
        background-color: #1e5b88;
        text-shadow: none;
        
    }
}

.email_form_admin {
    margin-top: 5%;
    margin-left:-35%;
    width: 100%;
    background-color: #1e5b88;
    text-shadow: none;
    
}

@media screen and (min-width: 1000px) {
    .email_form_admin {
        margin-top: 5%;
        margin-left: 0;
        width: 100%;
        background-color: #1e5b88;
        text-shadow: none;
        
    }
}

.EmployerPopup {
    margin-top: 3%;
    text-align: center;
}
