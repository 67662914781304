.LearningModulePanelCard {
    font-size: 3.8vmin;
    text-align: center;
    color: white;
    margin-top: .5%;
    margin-bottom: 6%;
    margin-left: 5%;
    margin-right: 0%;
    width: 40%;
    height: 25vmax;
    text-decoration: none;
    background-color:#1e5b88!important;
  
}

@media screen and (min-width: 1000px) {
    .LearningModulePanelCard {
        font-size: 1.8vmax;
        text-align: center;
        color: white;
        margin-top: .5%;
        margin-bottom: 3%;
        margin-right: 1.2%;
        margin-left: .6%;
        background-color:#1e5b88 !important;
        width: 18%;
        height: 20vmax;
        text-decoration: none;
    }
}

.LearningModulePanelCard:hover {
    background-color: #127ebf!important;
}