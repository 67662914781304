.registerbg {
    background-image: url("../Login/loginbg.png");
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    
}

.medcurity_logo {
    width: 25vmax;
}

.register_registerCard {
    border: white;
    background-color: transparent;
    width: 70%;
}

.register_pageHeaderText {
    color: white;
    text-shadow: 2px 2px 5px black;
    font-size: 3vmax;
}

.register_columnDivder {
    margin-top: 10%;
    animation: register_upwardsFadeIn 0.5s linear;
}


.register_formColumn {
    border-radius: 25px;
    padding-top: 2%;
    padding-bottom: 2%;
    
}

.register_formColumn:hover {
    padding-top: 2%;
    padding-bottom: 2%;
    animation: register_hoverFade 0.5s linear;
    background-color: #ffffff1f; 
}

.register_h3{
    color: white;
    text-align: center;
    width: 75%;
    margin-bottom: 2%;
    text-shadow: 2px 2px 2px black;
}

.register_p{
    color: white;
    text-align: center;
    width: 75%;
}

.register_registerResponse {
    margin-top: 2%;
    font-family: Lato,sans-serif;
    font-size: 1vmax;
    color: white;
    width: 72%;
    background-color: #0000003f;
}

.register_Form{
    width: 75%;
}

.register_button {
    color: white;
    background-color: #cc3333;
    font-family: Lato,sans-serif;
    font-size: 1.3vmax;
    text-align: center;
    width: 72%;
    height: 20%;
    margin-bottom: 3%;
}

.register_button:hover {
    animation: register_buttonHover .3s linear
}

@keyframes register_hoverFade { 
    from { background-color: #ffffff00; } 
    to { background-color: #ffffff1f; }
}

@keyframes register_upwardsFadeIn { 
    from { margin-top:15%; opacity:0; } 
    to { margin-top:10%; opacity:1; } 
}

@keyframes register_buttonHover { 
    0% { font-size: 1.3vmax; } 
    50% { font-size: 1.32vmax; } 
    0% { font-size: 1.3vmax; } 
}