.LearningModuleDirectoriesCard {
    font-size: 3.8vmin;
    text-align: center;
    color: white;
    margin-top: .5%;
    margin-bottom: 6%;
    margin-left: 8.5%;
    margin-right: -2%;
    background-color: #127ebf!important;
    width: 40%;
    height: 25vmax;
    text-decoration: none;
}

@media screen and (min-width: 1000px) {
    .LearningModuleDirectoriesCard {
        font-size: 1.8vmax;
        text-align: center;
        color: white;
        margin-top: .5%;
        margin-bottom: 3%;
        margin-right: .00001%;
        margin-left: 2%;
        background-color: #127ebf!important;
        width: 18%;
        height: 20vmax;
        text-decoration: none;
    }
}

.LearningModuleDirectoriesCard:hover {
    background-color: #93c2ec !important;
}

.LearningModulesDirectoriesHeader {
    font-family: Lato, sans-serif;
    margin-top: 3%;
    margin-bottom: 2%;
}


.LearningModuleDirectoriesCardFont {
    color: white;
    font-family: Lato, sans-serif;
    text-decoration: none;
}


.LearningModulesDirectories {
    font-family: Lato, sans-serif;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
}

.LearningModulesDirectoriesDashboard {
    margin-bottom: 3%;
    margin-right: 1.5%;
}

