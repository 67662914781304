.settingsProfilePicture {
    margin-top: 1%;
    margin-bottom: 1%;
    width: 20%;
    height: 20%;
    object-fit: cover;

}

.selectionBox {
    width: 100%;
    background-color: #e4e4e4 !important;
    border-radius: 10px;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 10px;

}

.settingsRow {
    background-color: #ffffffdf !important;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.settingSpacing {
    padding: 10px;
}

.selectedSetting {
    margin-top: 5%;
    background-color: #ffffff !important;
    color: black;
    text-align: center;
    width: 100%;
    font-size: 80%;
}

.selectedSetting.active {
    background-color: #1E5B88 !important;
    color: black;
    text-align: center;
    width: 100%;
    font-size: 80%;
}


.unselectedSetting {
    margin-top: 4%;
    background-color: #ffffff !important;
    color: black;
    text-align: center;
    width: 100%;
    font-size: 80%;
}

.unselectedSetting.active {
    margin-top: 4%;
    background-color: #1E5B88 !important;
    color: black;
    text-align: center;
    width: 100%;
    font-size: 80%;
}

.settingsContentPaneContainer {
    margin-top: 1%;
    margin-bottom: 2%;
}

.usernameText { 
    font-family: Lato,sans-serif;
    font-size: 140%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.usernameControl {
    border: #d8dae3;
}


.emailText {
    font-family: Lato,sans-serif;
    font-size: 140%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.emailControl {
    border: #d8dae3;
}


.emailInput {
    font-family: Lato,sans-serif;
    font-size: 60%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.usernameInput {
    font-family: Lato,sans-serif;
    font-size: 60%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.dropShadow {
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 10%;
    margin-right: 10%;
    color: black;
}

.shadowTab {
    margin-left: 20%;
    margin-right: 20%;
    height: 17.5vmin;
 
}

.marginTop {
    margin-top: 1%;
}

.settingsWideButton {
    background-color: #cc3333;
    color: white !important;
    border: none;
    font-size: 100%;
    width: 100%;
    margin-top: 1%;
    margin-bottom: 2%;
}

.settingsWideButton:hover {
    border: none;
    background-color: #da3e3e;
    color: #d8dae3;
}

.settingsWideButton:focus {
    border: none;
    background-color: #da3e3e;
}

.changePasswordText {
    font-family: Lato,sans-serif;
    font-size: 90%;
}