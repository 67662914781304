.cardBackgroundDash {
    margin-bottom: 2%;
    width: 110%;
    height: 22%;
    margin-left: -5%;

}

@media screen and (min-width: 1000px) {
    .cardBackgroundDash {
        margin-bottom: 2%;
        width: 100%;
        height: 22%;
        margin-left: 0%;
    }
}

.dashLeaderNumberPosition {
    color: #1e5b88;
    font-size: 90%;
    margin-top: 25%;
}

@media screen and (min-width: 1000px) {
    .dashLeaderNumberPosition {
        color: #1e5b88;
        font-size: 150%;
        margin-top: 4%;
    }
}

.profileImageDash {    
    height: 70%;
    margin-top: 12%;
    margin-left: -8%;
}

@media screen and (min-width: 1000px) {
    .profileImageDash {    
        height: 70%;
        margin-top: 3%;
    }
}

.userTextDash {
    color: #1e5b88;
    font-family: Lato,sans-serif;
    font-size: 80%;
    text-overflow: ellipsis;
    margin-top: 15%;
}

@media screen and (min-width: 1000px) {
    .userTextDash {
        color: #1e5b88;
        font-family: Lato,sans-serif;
        font-size: 120%;
        text-overflow: ellipsis;
        margin-top: 5%;
    }
}

.scoreTextDash {
    color: #127ebf;
    font-family: Lato,sans-serif;
    font-size: 90%;
    text-overflow: ellipsis;
    margin-top: 20%;
}

@media screen and (min-width: 1000px) {
    .scoreTextDash {
        color: #127ebf;
        font-family: Lato,sans-serif;
        font-size: 120%;
        text-overflow: ellipsis;
        margin-top: 5%;
    }
}

.pointsDash {
    color: #93c2ec;
    font-family: Lato,sans-serif;
    font-size: 90%;
    text-overflow: ellipsis;
    margin-top: 20%;
}

@media screen and (min-width: 1000px) {
    .pointsDash {
        color: #93c2ec;
        font-family: Lato,sans-serif;
        font-size: 120%;
        text-overflow: ellipsis;
        margin-top: 5%;
    }
}

.statusImageDash {
    width: 10%;
    height: 80%;
    margin-left: 6%;
    margin-bottom: 1%;
    margin-top: 1.5%;
}

