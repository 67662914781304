.questionNumbers {
    font-size: 140%;
    font-family: Lato,sans-serif; 
    font-weight: bold;
    text-shadow: 2px 2px 5px gray;
}

@media screen and (min-width: 1000px) {
    .questionNumbers {
        font-size: 200%;
        font-family: Lato,sans-serif; 
        font-weight: bold;
        text-shadow: 2px 2px 5px gray;
    }
}

.questionDesciption {
    color: #127ebf; 
    font-size: 80%;
    font-family: Lato,sans-serif;
    font-weight: bold;
    margin-bottom: 5%;
}

@media screen and (min-width: 1000px) {
    .questionDesciption {
        color: #127ebf; 
        font-size: 140%;
        font-family: Lato,sans-serif;
        font-weight: bold;
        margin-bottom: 1%;
    }
}

.answerQuizSelection {
    font-family: Lato,sans-serif;
    margin-top: 0%;
    width: 90%;
}

.individualQuestions {
    text-align: start;
    font-size: 100%;
    font-family: Lato,sans-serif;
}

/* RESULTS CSS */

.questionNumbersWrong {
    color: #1e5b88;
    margin-top: 2%;
}

.questionDesciptionWrong {
    color: #127ebf; 
    margin-bottom: 1%;
    margin-left: -2%;
}

.questionNumbersRight {
    color: #1e5b88;
    margin-top: 2%;
}

.questionDesciptionRight {
    color: #127ebf; 
    margin-bottom: 1%;
    margin-left: -2%;
}


