.navPills {
    background-color: #127ebf;
    color: white;
    margin-right: 1vmax;
    margin-left: 0vmax;
    margin-top: .5vmax;
    margin-bottom: .5vmax;
    font-size: 1vmax; 
}

.content_img{
    position: relative;
    width: 200px;
    height: 100px;
    float: left;
}

.menubarFont {
    color: white !important;
    font-family: Lato,sans-serif;
}

.menubarFont:hover {
    color: #d8dae3;
}


.MedcurityLogo {
    width: 15vmax;
}

.logo {
    border: white;
}

.pillz {
    border: none;
    background: none;
}