#title {
    resize: none;
}

#subtitle {
    resize:none
}

#description {
    resize:none
}

.content-textarea {
    resize:none
}