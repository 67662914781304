.ContentCard {
    margin-top: .7%;
    margin-left: 1%;
    margin-right: .5%;
    margin-bottom: .1%;
    border-radius: 10px 10px 10px 10px;
    background-color: #d8dae3;
    width: 98%;
    text-align: left;

    font-family: Lato,sans-serif;
    font-size: 90%;

    color: white;
}
.userRow {
    width: 100%;
    height: 100%;
}


.ContentInviteRequestCard {
    margin-top: .3%;
    margin-left: .5%;
    margin-right: .5%;
    margin-bottom: .5%;
    border-radius: 10px 10px 10px 10px;
    background-color: #4933c3;
    width: 33%;
    text-align: left;

    font-family: Lato,sans-serif;
    font-size: 90%;

    color: white;
}

.EmployerInviteRequests {
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 5%;
    margin-right: 14%;
    width: 90%;
}

.ContentCardHeader {
    margin-top: 7%;
    margin-left: 5%;
    margin-right: 3%;
    margin-bottom: .1%;
    font-size: 100%;
    text-shadow: 2px 2px 5px black;
}

@media screen and (min-width: 700px) {
    .ContentCardHeader {
        margin-top: 2%;
        margin-left: 3%;
        margin-right: 3%;
        margin-bottom: .1%;
        font-size: 180%;
        text-shadow: 2px 2px 5px black;
    }
}

.ContentInRowButton_remove {
    margin-top: 3%;
    margin-bottom: 5%;
    margin-left: -25%;
    background-color:#cc3333;
    font-size: 70%;
    width: 100%;
}

@media screen and (min-width: 700px) {
    .ContentInRowButton_remove {
        margin-top: 2%;
        margin-bottom: 3%;
        background-color:#cc3333;
        font-size: 100%;
    }    
}


.ContentInRowButton {
    margin-top: 3%;
    margin-bottom: 5%;
    margin-left: -25%;
    background-color:#127ebf;
    font-size: 70%;
    width: 100%;
}

@media screen and (min-width: 700px) {
    .ContentInRowButton {
        margin-top: 2%;
        margin-bottom: 3%;
        background-color:#127ebf;
        font-size: 100%;
    }    
}


.ContentCardValues {
    margin-top: 5%;
    margin-bottom: 1%;
    margin-left: 2%;
    font-size: 70%;
    width: 100%;
    overflow:hidden !important;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
}

@media screen and (min-width: 700px) {
    .ContentCardValues {
        margin-top: 3%;
        margin-bottom: 1%;
        margin-left: 2%;
        font-size: 100%;
        
    }
}

.ContentPopup {
    margin-top: 3%;
    text-align: center;
}

.InviteSubtitle {
    font-size: 1vmax;
}

.Content-container {
    background-color: #93c2ec;
    margin-bottom: 5%;
}

#content-name {
    color:black;
}

#content-title {
    color:white;
}

.add-btn {
    background-color: #1e5b88;
    margin-top: 5%;
    font-size: 70%;
    margin-left: 25%;
}

@media screen and (min-width: 700px) {
    .add-btn {
        background-color: #1e5b88;
        margin-top: 10%;
        font-size: 90%;
    }
}